<template>
  <div class="page">
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="item"
          @click="toRouteTitle(item)"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="item-l">
            <div class="name">{{ item.topicTitle || "" }}</div>
            <div class="item-l-b">
              <div class="time">{{ item.createTime }}</div>
              <div class="sum">
                <img src="./img/lookIcon.png" />
                <div class="num">{{ item.readCount }}</div>
              </div>
            </div>
          </div>
          <div class="item-r">
            <img :src="item.topicPicture" />
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { getTopicListURL } from "./api.js";
import wx from "weixin-js-sdk";
export default {
  name: "establishSsystem",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    communityId() {
      return this.$store.state.communityId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  methods: {
    // 点击跳转话题详情页
    toRouteTitle(item) {
      let params = {
        id: item.id,
      };
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/subPages/topic/topicTitle?params=${JSON.stringify(params)}`,
        });
      }
    },
    onLoad() {
      this.getList();
    },
    getList() {
      let params = {
        curPage: this.requestData.curPage,
        pageSize: this.requestData.pageSize,
        tenantId: this.tenantId,
        houseId: this.houseId || this.communityId || undefined,
        sortField: "NEW",
        topicType: "28",
      };
      this.$axios.get(`${getTopicListURL}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            this.listData.push(item);
          });
        }
      });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: #f0f2f5;
  .list {
    .item {
      width: 100%;
      height: 186px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      display: flex;
      .item-l {
        flex: 1;
        height: 100%;
        box-sizing: border-box;
        padding: 26px 30px 0 30px;
        position: relative;
        .name {
          font-size: 32px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .item-l-b {
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #b4b4b4;
          position: absolute;
          box-sizing: border-box;
          bottom: 28px;
          left: 0;
          padding-left: 30px;
          .time {
            white-space: nowrap;
          }
          .sum {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              display: block;
              width: 28px;
              height: 26px;
            }
          }
        }
      }
      .item-r {
        padding: 16px 30px 20px 0;
        box-sizing: border-box;
        width: 256px;
        height: 100%;
        img {
          border-radius: 12px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>