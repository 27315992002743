import { render, staticRenderFns } from "./establishSsystem.vue?vue&type=template&id=d6d83cdc&scoped=true"
import script from "./establishSsystem.vue?vue&type=script&lang=js"
export * from "./establishSsystem.vue?vue&type=script&lang=js"
import style0 from "./establishSsystem.vue?vue&type=style&index=0&id=d6d83cdc&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6d83cdc",
  null
  
)

export default component.exports